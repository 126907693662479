// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  getReviewStatus: {
    data: null,
  },
  publishStatus: {
    data: null,
  },
};
