// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  errror: false,
  discountAddOfferStatus: {
    data: null,
  },
  discountGetOfferStatus: {
    data: null,
  },
};
